// src/context/LocationContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import supabase from '../lib/supabase';
import { AccountContext } from './AccountContext';

const LocationContext = createContext();
const UpdateLocationContext = createContext();

const LocationProvider = ({ children }) => {
  const { user } = useContext(AccountContext);

  const [activeLocation, setActiveLocation] = useState(() => {
    const savedLocationId = localStorage.getItem('activeLocation');
    return savedLocationId ? JSON.parse(savedLocationId) : { id: 0, name: '' };
  });
  const [locations, setLocations] = useState([]);
  const [maxSubscriptionLevel, setMaxSubscriptionLevel] = useState(0);
  const [isSyncing, setIsSyncing] = useState(false);

  useEffect(() => {
    const fetchLocations = async () => {
      const { data, error } = await supabase
        .from('locations')
        .select('*')
        .eq('archived', 0)
        .order('ordinal', { ascending: true });

      if (!error) {
        setLocations(data);
        const maxLevel = data.reduce((max, loc) => Math.max(max, loc.subscription_level), 0);
        setMaxSubscriptionLevel(maxLevel);
      }
    };
    fetchLocations();
  }, []);

  useEffect(() => {
    if (locations.length > 0) {
      const foundItem = locations.find(item => item.id === activeLocation.id);
      if (!foundItem) {
        setActiveLocation(locations[0]); //If we didn't find the activeLocation, we need to default to the first location in the array so we do'nt break the page displays that depend on activeLocation.id
      } else {
        setActiveLocation(foundItem); //Do this to update the saved data with the data from the DB, in case it's different
      }
      //console.log(activeLocation,locations[0]);
    } //else there are no locations anyhow, so we have nothing to select
  },[locations,activeLocation.id]);

  useEffect(() => {
    if (activeLocation !== null) {
      localStorage.setItem('activeLocation', JSON.stringify(activeLocation));
    } else {
      localStorage.removeItem('activeLocation');
    }
  }, [activeLocation]);

  /* Commented out since we added the order & menu updates in other ways. Likely need to remove this once we show that the new way works reliably
  useEffect(() => {
    let intervalId;
    if (maxSubscriptionLevel === 2) {
      //console.log("Adding soft refresh for subscription level 2");
      intervalId = setInterval(() => {
        //console.log("Soft refreshing both");
        softMenuRefreshFromApi();
        //softOrderRefreshFromApi();
      }, 60 * 1000); // 60 * 1000 ms = 1 minute
    }
    if (maxSubscriptionLevel === 3) {
      //console.log("Adding soft refresh for subscription level 3");
      intervalId = setInterval(() => {
        //console.log("Soft refresing only the menu");
        //softMenuRefreshFromApi();
      }, 60 * 1000); // 60 * 1000 ms = 1 minute
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [maxSubscriptionLevel]);
  */

  const softMenuRefreshFromApi = async () => {
    console.log("Soft refreshing menu");
    const lastMenuRefresh = localStorage.getItem('lastMenuRefresh');
    const oneHour = 60 * 60 * 1000;

    if (!user) return;

    if (!lastMenuRefresh || (Date.now() - lastMenuRefresh) > oneHour) {
      //console.log("Hard refreshing menu");
      const success = await hardMenuRefreshFromApi();
      if (success) {
        localStorage.setItem('lastMenuRefresh', Date.now());
      }
    }
  };

  const softOrderRefreshFromApi = async () => {
    /* Commented out because I moved the order refresh to the real time functionality so we don't need to worry about it here
    //console.log("Soft refreshing orders");
    const lastOrderRefresh = localStorage.getItem('lastOrderRefresh');
    const oneHour = 60 * 60 * 1000;

    if (!user) return;

    if (!lastOrderRefresh || (Date.now() - lastOrderRefresh) > oneHour) {
      //console.log("Hard refreshing orders");
      const success = await hardOrderRefreshFromApi();
      if (success) {
        localStorage.setItem('lastOrderRefresh', Date.now());
      }
    }
    */
  };

  const hardMenuRefreshFromApi = async () => {
    if (!user.access_token) {
      console.error("Error: No user");
      return false;
    }
    if (maxSubscriptionLevel >= 2) {
      setIsSyncing(true);
      try {
        const response = await fetch('https://yzxiqswbpvwvdzgwktpt.supabase.co/functions/v1/square-sync', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.access_token}`
          }
        });
        if (!response.ok) {
          console.error(response.error);
          return false;
        }
        return true;
      } catch (ex) {
        console.error("Error in order refresh from api:",ex);
      } finally {
        setIsSyncing(false);
      }
    }
    return false;
  };
  
  const hardOrderRefreshFromApi = async () => {
    /*
    if (!user.access_token) {
      console.error("Error: No user");
      return false;
    }
  
    if (maxSubscriptionLevel >= 2 && user.access_token) {
      setIsSyncing(true);
      
      try {
        let retry = true; // Flag to indicate whether to retry
        let attempts = 0; // To limit retry attempts
  
        while (retry && attempts < 60) { // Retry max 60 times, just to have some kind of limiter here. We should never hit this unless there's a problem
          const response = await fetch('https://yzxiqswbpvwvdzgwktpt.supabase.co/functions/v1/square-orders', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${user.access_token}`
            }
          });
  
          if (response.status === 202) {
            // Sync is still in progress, wait for 2 seconds and retry
            console.log('Sync is still in progress, retrying...');
            await new Promise(resolve => setTimeout(resolve, 2000)); // 2-second delay before retrying
            attempts += 1;
          } else if (!response.ok) {
            console.error('Error:', response.statusText);
            return false;
          } else {
            retry = false; // Sync completed successfully, exit loop
          }
        }
  
        // If the function completed without retrying or after successful retries
        return !retry;
      } catch (ex) {
        console.error("Error in order refresh from api:",ex);
      } finally {
        setIsSyncing(false);
      }
    } else if (!user.access_token) {
      console.log("No JWT Token");
    }
  
    return false;
    */
  };

  const updateLocationData = async (locationId, fieldName, newValue) => {
    // Update the location in the database
    const { error } = await supabase
      .from('locations')
      .update({ [fieldName]: newValue })
      .eq('id', locationId);
  
    if (error) {
      console.error("Failed to update location in database:", error);
      return false;
    }
  
    // If the database update was successful, update the local state
    setLocations(prevLocations => prevLocations.map(location => {
      if (location.id === locationId) {
        return { ...location, [fieldName]: newValue };
      }
      return location;
    }));
  
    // Check if the activeLocation needs to be updated
    if (activeLocation.id === locationId) {
      setActiveLocation(prevActiveLocation => ({
        ...prevActiveLocation,
        [fieldName]: newValue
      }));
    }
  
    return true;
  };  

  return (
    <LocationContext.Provider value={{ activeLocation, locations, isSyncing, maxSubscriptionLevel }}>
      <UpdateLocationContext.Provider value={{ updateLocationData, setActiveLocation, setLocations, hardMenuRefreshFromApi, hardOrderRefreshFromApi, softMenuRefreshFromApi, softOrderRefreshFromApi }}>
        {children}
      </UpdateLocationContext.Provider>
    </LocationContext.Provider>
  );
};

export { LocationContext, UpdateLocationContext, LocationProvider };
