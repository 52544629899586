import React, { Fragment, useContext } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import MetisMenu from "react-metismenu";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import {
  DashboardsNav,
  InventoryNav,
  MenuNav,
  RecipesNav,
  ChecklistNav,
  TabletNav
} from "./NavItems";
import { AccountContext } from "../../context/AccountContext";
import { LocationContext } from "../../context/LocationContext";

const Nav = (props) => {
  const enableMobileMenu = useSelector((state) => state.ThemeOptions.enableMobileMenu);
  const dispatch = useDispatch();

  const toggleMobileSidebar = () => {
    dispatch(setEnableMobileMenu(!enableMobileMenu));
  };

  //const { account } = useContext(AccountContext);
  const { maxSubscriptionLevel } = useContext(LocationContext);
//console.log(maxSubscriptionLevel);
  return (
    <Fragment>
      <h5 className="app-sidebar__heading">Back Office</h5>
      <MetisMenu content={DashboardsNav(maxSubscriptionLevel)} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu" classNameStateIcon="fa fa-chevron-down"/>
      {(InventoryNav(maxSubscriptionLevel).length > 0 && 
        <>
        <h5 className="app-sidebar__heading">Inventory</h5>
        <MetisMenu content={InventoryNav(maxSubscriptionLevel)} onSelected={toggleMobileSidebar} activeLinkFromLocation
          className="vertical-nav-menu" classNameStateIcon="fa fa-chevron-down"/>
        </>
      )}
      <h5 className="app-sidebar__heading">Recipes & Ingredients</h5>
      <MetisMenu content={RecipesNav(maxSubscriptionLevel)} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu" classNameStateIcon="fa fa-chevron-down"/>
      {(ChecklistNav(maxSubscriptionLevel).length > 0 && 
        <>
        <h5 className="app-sidebar__heading">Checklists</h5>
        <MetisMenu content={ChecklistNav(maxSubscriptionLevel)} onSelected={toggleMobileSidebar} activeLinkFromLocation
          className="vertical-nav-menu" classNameStateIcon="fa fa-chevron-down"/>
        </>
      )}
      {(TabletNav(maxSubscriptionLevel).length > 0 && 
        <>
        <h5 className="app-sidebar__heading">Tablets</h5>
        <MetisMenu content={TabletNav(maxSubscriptionLevel)} onSelected={toggleMobileSidebar} activeLinkFromLocation
          className="vertical-nav-menu" classNameStateIcon="fa fa-chevron-down"/>
        </>
      )}
      {/*(MenuNav(maxSubscriptionLevel).length > 0 && 
        <>
        <h5 className="app-sidebar__heading">Menus</h5>
        <MetisMenu content={MenuNav(maxSubscriptionLevel)} onSelected={toggleMobileSidebar} activeLinkFromLocation
          className="vertical-nav-menu" classNameStateIcon="fa fa-chevron-down"/>
        </>
      )*/}
    </Fragment>
  );
};

export default withRouter(Nav);
