import React, { createContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import supabase from '../lib/supabase';

const AccountContext = createContext();
const UpdateAccountContext = createContext();

const AccountProvider = ({ children }) => {
  const [account, setAccount] = useState(null);
  const [user, setUser] = useState(null);  // Add state to hold the Supabase user object
  const history = useHistory();

  useEffect(() => {
    // Fetch the user session and account info from Supabase
    const fetchUser = async () => {
      //console.log("in fetchUser");
      supabase.auth.getSession().then(async ({ data: { session } }) => {
        //console.log("Has session",session);

        const user = session?.user;
        user.access_token = session?.access_token;
        setUser(user);  // Set the user object from session
        if (user) {
          //console.log("User",user);
          const { data, error } = await supabase
            .from('userinfo')
            .select('*, oauth_credentials(merchant_id)')
            .eq('user_id', user.id)
            .eq('oauth_credentials.integration_name','square')
            .single();
			    //console.log(data,data?.oauth_square?.merchant_id);
          //console.log("In fetchUser - userinfo data:",data);
          if (!error) {
            data.square_connected = (data?.oauth_credentials?.[0]?.merchant_id !== undefined && data?.oauth_credentials?.[0]?.merchant_id !== null);
            setAccount(data);
          } else {
            console.error('Error fetching user info:', error);
          }
        }
      });
    }; 
    
    // Listen for the visibilityState to update session when visible
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        await fetchUser();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Initial fetch
    fetchUser();

    // Cleanup function
    return () => {
      //console.log("Cleaning up...");
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const logout = async () => {
    //console.log("Logging out!");
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error logging out:', error.message);
    } else {
      setAccount(null);
      history.push('/login');
    }
  };

  return (
    <AccountContext.Provider value={{ account, user, setAccount, logout }}>
      <UpdateAccountContext.Provider value={setAccount}>
        {children}
      </UpdateAccountContext.Provider>
    </AccountContext.Provider>
  );
};

export { AccountContext, UpdateAccountContext, AccountProvider };
