import React, { useEffect, useState, Suspense, lazy, Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import Loader from "react-loaders";

import { LocationProvider } from './context/LocationContext';
import { AccountProvider } from './context/AccountContext';

import supabase from './lib/supabase';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/general.css';

import { ToastContainer } from "react-toastify";

import AppHeader from "./Layout/AppHeader/";
import AppSidebar from "./Layout/AppSidebar/";
import AppFooter from "./Layout/AppFooter/";
import ThemeOptions from "./Layout/ThemeOptions/";
import QuickstartPanel from './components/Quickstart';

//Public pages
const Login = lazy(() => import('./public/login'));
const Register = lazy(() => import('./public/register'));
const Home = lazy(() => import('./public/index'));
const ForgotPassword = lazy(() => import('./public/forgot_password'));

//Logged in pages
const AdminHome = lazy(() => import('./pages/index'));

const OpsDashboard = lazy(() => import('./pages/dashboards/ops'));

const HuddleBoardReport = lazy(() => import('./pages/reports/huddle_board'));

const InventoryLedger = lazy(() => import('./pages/inventory/ledger'));
const InventoryLocations = lazy(() => import('./pages/inventory/zones/inventory_zones'));
const InventoryLocationComponents = lazy(() => import('./pages/inventory/zones/inventory_zone_components'));

const IngredientsList = lazy(() => import('./pages/ingredients/ingredients'));
const RecipeList = lazy(() => import('./pages/ingredients/recipes'));
const ModifierList = lazy(() => import('./pages/ingredients/modifiers'));
const RecipeDetail = lazy(() => import('./pages/ingredients/recipeDetail'));

const BrandsList = lazy(() => import('./pages/admin/ingredients/brands'));
const SuppliersList = lazy(() => import('./pages/admin/ingredients/suppliers'));

const BakeGroupsList = lazy(() => import('./pages/admin/recipes/bake_groups'));
const PrepCategoriesList = lazy(() => import('./pages/admin/recipes/prep_categories'));
const PrepCategoryDetail = lazy(() => import('./pages/admin/recipes/prep_category_detail'));

const Checklists = lazy(() => import('./pages/admin/checklists/checklists'));
const ChecklistDetail = lazy(() => import('./pages/admin/checklists/checklist_details'));

const AccountInfoEdit = lazy(() => import('./pages/account/account'));
const NewPassword = lazy(() => import('./pages/account/new_password'));
const LocationsList = lazy(() => import('./pages/account/locations'));
const Subscriptions = lazy(() => import('./pages/account/subscriptions'));

const TabletInfo = lazy(() => import('./pages/tablet_admin/info'));
const KDSAdmin = lazy(() => import('./pages/tablet_admin/kds'));

const InventoryFullCount = lazy(() => import('./pages/inventory/counts/full_count'));
const InventorySingleCount = lazy(() => import('./pages/inventory/counts/single_count'));
const InventoryCreateOrder = lazy(() => import('./pages/inventory/ordering/create_order'));
const InventoryReceiveOrder = lazy(() => import('./pages/inventory/ordering/receive_order'));

//OAuth Connections
const AccountConnect = lazy(() => import('./pages/account/connect'));
const SquareOAuth = lazy(() => import('./pages/account/connect/oauth/square'));


const App = () => {
  const history = useHistory();
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      //console.log('Initial session:', session);
      setSession(session);
    });

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      //console.log('Auth state change:', event, session);
      setSession(session);
    });

    return () => authListener.subscription.unsubscribe();
  }, []);
  
  useEffect(() => {
    const handleMagicLinkRedirect = async () => {
      let hash = window.location.hash.substring(1);
      if (hash.startsWith('/')) {
        hash = hash.substring(1);
      }
  
      const params = new URLSearchParams(hash);
      const type = params.get('type'); // Get the 'type' parameter from the URL
      const access_token = params.get('access_token');
      const refresh_token = params.get('refresh_token');
  
      if (access_token && refresh_token) {
        const { error } = await supabase.auth.setSession({ access_token, refresh_token });
  
        if (error) {
          console.error('Error setting session:', error.message);
        } else {
          if (type === 'recovery') {
            // If the link is for password recovery, note that and redirect to the reset password page
            sessionStorage.setItem('passwordRecoveryFlow', 'true');
            history.push('/admin/new_password');
          } else {
            // Otherwise, redirect to the dashboard or another authenticated page
            history.push('/dashboard');
          }
        }
      }
    };
  
    handleMagicLinkRedirect();
  }, [history]);

  return (
      <Router>
        {session && session.user ? (
          <AccountProvider>
            <LocationProvider>
              <Suspense
                fallback={
                  <div className="loader-container">
                    <div className="loader-container-inner">
                      <div className="text-center">
                        <Loader type="ball-pulse-rise" />
                      </div>
                      <h6 className="mt-5">
                        Loading...
                      </h6>
                    </div>
                  </div>
                }
              >
                <Fragment>
                  <AppHeader />
                  <div className="app-main">
                    <AppSidebar />
                    <div className="app-main__outer">
                      <div className="app-main__inner">
                          <Route exact path="/dashboards/ops" component={OpsDashboard} />

                          <Route exact path="/reports/huddle_board" component={HuddleBoardReport} />

                          <Route exact path="/inventory" component={InventoryLedger} />

                          <Route exact path="/inventory/full_count" component={InventoryFullCount} />
                          <Route exact path="/inventory/single_count" component={InventorySingleCount} />

                          <Route exact path="/inventory/new_order" component={InventoryCreateOrder} />
                          <Route exact path="/inventory/receive_order" component={InventoryReceiveOrder} />

                          <Route exact path="/inventory/inventory_zones" component={InventoryLocations} />
                          <Route exact path="/inventory/inventory_zones/detail/:inventory_zone_id" component={InventoryLocationComponents} />

                          <Route exact path="/ingredients" component={IngredientsList} />
                          <Route exact path="/ingredients/modifiers" component={ModifierList} />
                          <Route exact path="/ingredients/recipes" component={RecipeList} />
                          <Route exact path="/ingredients/recipes/detail/:item_id" component={RecipeDetail} />

                          <Route exact path="/admin/brands" component={BrandsList} />
                          <Route exact path="/admin/suppliers" component={SuppliersList} />
                          <Route exact path="/admin/bake_groups" component={BakeGroupsList} />
                          <Route exact path="/admin/prep_categories" component={PrepCategoriesList} />
                          <Route exact path="/admin/prep_categories/detail/:prep_category_id" component={PrepCategoryDetail} />
                          <Route exact path="/admin/checklists" component={Checklists} />
                          <Route exact path="/admin/checklists/detail/:checklist_id" component={ChecklistDetail} />

                          <Route exact path="/account" component={AccountInfoEdit} />
                          <Route exact path="/account/new_password" component={NewPassword} />
                          <Route exact path="/account/locations" component={LocationsList} />
                          <Route exact path="/account/subscriptions" component={Subscriptions} />
                          <Route exact path="/account/connect" component={AccountConnect} />

                          <Route exact path="/tablets/info" component={TabletInfo} />
                          <Route exact path="/tablets/kds" component={KDSAdmin} />
                          <Route exact path="/tablets/special" component={KDSAdmin} />

                          <Route exact path="/" component={AdminHome} />
                          <Route exact path="/square-oauth-redirect" component={SquareOAuth} />
                      </div>
                      <AppFooter />
                    </div>
                  </div>
                  <ToastContainer/>
                </Fragment>
              </Suspense>
            </LocationProvider>
          </AccountProvider>
        ) : (
          <Suspense
            fallback={
              <div className="loader-container">
                <div className="loader-container-inner">
                  <div className="text-center">
                    <Loader type="ball-pulse-rise" />
                  </div>
                  <h6 className="mt-5">
                    Loading...
                  </h6>
                </div>
              </div>
            }
          >
            <Switch>
              <Route path="/register" exact component={Register} />
              <Route path="/login" exact component={Login} />
              <Route path="/forgot_password" exact component={ForgotPassword} />
              <Route path="/" exact component={Home} />
            </Switch>
          </Suspense>
        )}
      </Router>
  );
};

export default App;
