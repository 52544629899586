export const DashboardsNav = (membershipLevel) => [
  {
    icon: "gauge-high",
    label: "Dashboard",
    to: "/dashboards/ops/",
  },
  ...(membershipLevel >= 2 ? [{
    icon: "chart-line",
    label: "Reports",
    content: [
      {
        label: "Weekly Huddle Board",
        to: '/reports/huddle_board/'
      },
      {
        label: "FB Ad Stats",
        to: "/reports/fb_ad_stats/",
      },
      {
        label: "Staffing Forecast",
        to: "/reports/staffing_forecast/",
      },
      {
        label: "Pricing & Profits",
        to: "/reports/pricing_and_profits/",
      },
      {
        label: "Missing Modifier Amounts",
        to: "/reports/missing_modifiers/",
      },
    ],
  }] : []),
];

// Similar updates for other navigation items if needed
export const InventoryNav = (membershipLevel) => [
  {
    icon: "list-check",
    label: "Count",
    content: [
      {
        label: "Full Count",
        to: "/inventory/full_count/",
      },
      {
        label: "Single Count",
        to: "/inventory/single_count/",
      }
    ]
  },
  {
    icon: "cart-shopping",
    label: "Ordering",
    content: [
      {
        label: "Create New Order",
        to: "/inventory/new_order/",
      },
      {
        label: "Receive Order",
        to: "/inventory/receive_order/",
      },
    ],
  },
  {
    icon: "bars",
    label: "Ledger",
    to: "/inventory/",
  },
  {
    icon: "magnifying-glass-location",
    label: "Inventory Zones",
    to: "/inventory/inventory_zones/",
  },
];
export const RecipesNav = (membershipLevel) => [
  ...(membershipLevel >= 2 ? [
    {
      icon: "tachograph-digital",
      label: "Recipes",
      content: [{
        icon: "tachograph-digital",
        label: "Recipes",
        to: "/ingredients/recipes/",
      },
      {
        icon: "location-crosshairs",
        label: "Prep Categories",
        to: "/admin/prep_categories/",
      },
      {
        icon: "location-crosshairs",
        label: "Bake Groups",
        to: "/admin/bake_groups/",
      }
    ],
  }] : []),
  {
    icon: "mortar-pestle",
    label: "Ingredients",
    content: [
      {
        icon: "mortar-pestle",
        label: "Ingredients",
        to: "/ingredients/",
      },
      {
        icon: "location-crosshairs",
        label: "Brands",
        to: "/admin/brands/",
      },
      {
        icon: "location-crosshairs",
        label: "Suppliers",
        to: "/admin/suppliers/",
      },
    ]
  },
  ...(membershipLevel >= 2 ? [{
    icon: "pen-to-square",
    label: "Modifiers",
    to: "/ingredients/modifiers/",
  }] : []),
];
export const MenuNav = (membershipLevel) => 
  (membershipLevel >= 3 ? [
    {
      icon: "pen-to-square",
      label: "Menus",
      to: "/menu/dashboard/",
    },
  ] : []);
export const ChecklistNav = (membershipLevel) => [
  ...(membershipLevel >= 3 ? [{
    icon: "square-check",
    label: "Checklists",
    to: "/admin/checklists/",
  }] : []),
];
export const TabletNav = (membershipLevel) => [
  ...(membershipLevel === 2 ? [{
    icon: "circle-info",
    label: "Tablet Info",
    to: "/tablets/info/",
  }] : []),
  ...(membershipLevel > 2 ? [
    {
      icon: "tablet-screen-button",
      label: "Tablet Admin",
      content: [{
          label: "Tablet Info",
          to: "/tablets/info/",
        },
        ...(membershipLevel >= 3 ? [
          {
            icon: "table-columns",
            label: "KDS",
            to: "/tablets/kds/",
          },
          {
            icon: "",
            label: "Special",
            to: "/tablets/special/",
          },
        ] : [])
      ],
    },
  ] : []),

];
